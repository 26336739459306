:root {
  --toastify-color-progress-info: #4065ad !important;
  --toastify-icon-color-info: #4065ad !important;
  --toastify-color-progress-error: #ad4054 !important;
  --toastify-icon-color-error: #ad4054 !important;
  --toastify-color-progress-success: #40ad4b !important;
  --toastify-icon-color-success: #40ad4b !important;
  --toastify-toast-width: max-content !important;
  --toastify-text-color-light: #393939 !important;
}

body {
  overflow-x: hidden;
}

.page-wrapper {
  height: 100%;
  position: relative;
}

.detail-column-wrapper {
  flex: 1;
}

.detail-column-wrapper .ms-TextField {
  width: 100%;
}

.list-wrapper {
  width: 100%;
  position: relative;
  height: 100%;
}

.required label:after {
  content: " *";
  padding-right: 12px;
}

.readonly label {
  color: initial;
}

.readonly .ms-Dropdown-caretDownWrapper {
  visibility: hidden;
}

.readonly .ms-TextField-fieldGroup,
.is-disabled {
  border-color: #e4e4e4 !important;
}
.readonly .ms-TextField-fieldGroup:after,
.is-disabled .ms-TextField-fieldGroup:after {
  border: none;
}

.readonly .ms-TextField-fieldGroup:active,
.is-disabled .ms-TextField-fieldGroup:active {
  border-color: #e4e4e4;
}

.ms-TextField-errorMessage > span {
  white-space: pre-wrap;
}

.heading {
  /* font-weight: 700 !important; */
}

progress::-webkit-progress-bar {
  background: #d1d1d1;
}
progress::-webkit-progress-value {
  background-color: #4065ad;
}
